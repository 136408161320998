<template>
	<div class="totalBox">
		<jy-query ref="checkForm" :model="formInline" label-width="90px">
			<jy-query-item :span="8" label="查询类型" prop="type">
				<el-radio-group v-model="type" @change="oncheck">
					<el-radio label="0">线路</el-radio>
					<el-radio label="1">车辆</el-radio>
					<el-radio label="2">司机</el-radio>
				</el-radio-group>
			</jy-query-item>
			<jy-query-item prop="routeId" label="线路" v-if="type == '0' && isshow">
				<el-select v-model="routeIds" @change="routeChange">
					<el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="customNo" label="自编号" v-if="type == '1' && isshow">
				<el-input v-model="formInline.customNo"></el-input>
			</jy-query-item>
			<jy-query-item prop="driverName" label="司机名称" v-if="type == '2' && isshow">
				<el-input v-model="formInline.driverName"></el-input>
			</jy-query-item>
			<jy-query-item prop="workNum" label="工号" v-if="type == '2' && isshow">
				<el-input v-model="formInline.workNum"></el-input>
			</jy-query-item>
			<jy-query-item label="查询方式" prop="queryType">
				<el-radio-group v-model="formInline.queryType" @change="oncheck">
					<el-radio label="1">月</el-radio>
					<el-radio label="0">年</el-radio>
				</el-radio-group>
			</jy-query-item>
			<jy-query-item label="" prop="queryYear" v-if="formInline.queryType == '0' && isshow">
				<el-date-picker
					v-model="formInline.queryYear"
					:clearable="false"
					type="year"
					value-format="yyyy"
					placeholder="选择年"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item label="" prop="queryMonth" v-if="formInline.queryType == '1' && isshow">
				<el-date-picker
					v-model="formInline.queryMonth"
					:clearable="false"
					type="month"
					value-format="yyyy-MM"
					placeholder="选择月"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item label="选择车辆" prop="vehicleIds" :span="2" v-if="type == '1' && isshow">
				<el-input v-model="formInline.vehicleNames" placeholder="选择车辆" readonly @focus="chooseCar"></el-input>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('list') || true">查询</el-button>
				<el-button type="primary" plain @click="resetForm('checkForm')" v-if="btnexist('reset') || true">重置</el-button>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="onexport" v-if="btnexist('export')">导出</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" fixed align="center"></jy-table-column>
			<jy-table-column prop="driverName" label="司机" min-width="160" fixed v-if="type == '2'"></jy-table-column>
			<jy-table-column prop="workNum" label="工号" min-width="100" fixed v-if="type == '2'"></jy-table-column>
			<jy-table-column prop="routeNa" label="所属线路" min-width="220" fixed v-if="type == '1' || type == '2'"></jy-table-column>
			<jy-table-column prop="routeNa" label="线路名称" min-width="220" fixed v-if="type == '0'"></jy-table-column>
			<jy-table-column prop="customNo" label="自编号" min-width="160" fixed v-if="type == '1'"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="160" fixed v-if="type == '1'"></jy-table-column>
			<jy-table-column prop="totalMil" label="总里程(km)" min-width="100" fixed></jy-table-column>
			<jy-table-column :prop="item" :label="item" min-width="100" v-for="item in propList" :key="item"></jy-table-column>
			<!-- <el-table-column :label="item" v-for="item in propList" :key="item" class-name="jy_table_column">
                <el-table-column
                :prop="item"
                label="营运里程"
                width="120" class-name="jy_table_column">
                </el-table-column>
                <el-table-column
                :prop="item"
                label="非营运里程"
                width="120" class-name="jy_table_column">
                </el-table-column>
            </el-table-column> -->
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<car-selectbatch ref="carSelectbatch" @choose="chooseCarList"></car-selectbatch>
	</div>
</template>
<script>
import carSelectbatch from "@/components/pages/admin/resourcesAllocation/carDriver/carSelectbatch";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			isshow: true,
			formInline: {
				routeId: "",
				customNo: "",
				vehicleIds: "",
				vehicleNames: "",
				queryType: "1",
				queryYear: "",
				queryMonth: "",
				driverName: "",
				workNum: ""
			},
			type: "0",
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			routeList: [],
			carList: [],
			propList: [],
			routeIds: [],

			btnMenuId: "scheduleStatisticalAnalysisGPStotal"
		};
	},
	watch: {
		"formInline.queryType"(val) {
			console.log(val);
			this.isshow = false;
			this.$nextTick(() => {
				this.isshow = true;
			});
		}
	},
	mixins: [btnMixins],
	components: {
		carSelectbatch
	},
	activated() {},
	async created() {
		this.type = "0";
		this.getNowData();
		await this.getRouteList();
		this.getList();
	},

	methods: {
		getNowData() {
			let date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth();
			if (month == 0) {
				year -= 1;
				month = 12;
			}
			month = month < 10 ? "0" + month : month;
			this.formInline.queryYear = new Date().Format("yyyy");
			this.formInline.queryMonth = year + "-" + month;
		},
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			await this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
				// this.formInline.routeId = this.routeList[0].routeId
				// if(this.routeList.length){
				//     this.formInline.routeId = this.routeList[0].routeId
				// }
			});
		},
		getList() {
			let url = "/stat/route/mileage/queryRouteMilByMonth";
			if (this.type == "1") {
				url = "/stat/route/mileage/queryVehicleByMonth";
			} else if (this.type == "2") {
				url = "/stat/route/mileage/driverByMonth";
			}
			let option = {
				...this.formInline,
				pageSize: this.pageSize,
				pageIndex: this.pageIndex
			};
			if (option.queryType == 1) {
				option.date = option.queryMonth;
			} else {
				url = "/stat/route/mileage/queryRouteMilByYear";
				if (this.type == "1") {
					url = "/stat/route/mileage/queryVehicleByYear";
				} else if (this.type == "2") {
					url = "/stat/route/mileage/driverByYear";
				}
				option.date = option.queryYear;
			}
			Object.keys(option).forEach(key => {
				if (/^\s*$/.test(option[key]) || option[key] === undefined) {
					delete option[key];
				}
			});
			this.$http.post(url, option).then(({ detail }) => {
				this.dataList = detail.list;
				console.log(this.dataList);
				if (this.dataList.length) {
					this.propList = this.dataList[0].statGpsMileageDetailVOList.map(item => {
						let list = item.date.split("-");
						if (option.queryType == 1) {
							return list[list.length - 1];
						} else {
							return list[list.length - 1];
						}
					});
					console.log(this.propList, this.dataList[0].statGpsMileageDetailVOList, "------z7kla111111n");
					this.propList.sort((a, b) => {
						return Number(a) - Number(b);
					});
					this.propList = this.propList.map(item => {
						if (option.queryType == 1) {
							return item + "号";
						} else {
							return item + "月";
						}
					});
					console.log(this.propList, "------z7klan");
				} else {
					this.propList = [];
				}
				this.dataList.forEach(item => {
					item.statGpsMileageDetailVOList.forEach(one => {
						let list = one.date.split("-");
						let prop = "";
						if (option.queryType == 1) {
							prop = list[list.length - 1] + "号";
						} else {
							prop = list[list.length - 1] + "月";
						}
						item[prop] = one.mil;
					});
				});
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.carList = [];
			this.routeIds = [];
			this.formInline.vehicleNames = "";
			this.type = "0";
			this.formInline.routeId = "";
			this.formInline.queryType = "1";
			this.formInline.customNo = "";
			// if(this.routeList.length){
			//     this.formInline.routeId = this.routeList[0].routeId
			// }
			this.getNowData();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		chooseCar() {
			this.$refs.carSelectbatch.init(this.carList);
		},
		routeChange() {
			this.formInline.routeId = this.routeIds.toString();
		},
		chooseCarList(list) {
			this.carList = list;
			this.formInline.vehicleNames = list.map(item => item.plateNum).join(",");
			this.formInline.vehicleIds = list.map(item => item.vehicleId).join(",");
		},
		//导出
		onexport() {
			let url = "/stat/gpsMileage/export";
			let option = {
				...this.formInline
			};
			option.queryMonth = option.queryMonth + "-01";
			this.$http.post(url, option, { type: "format", isExcel: true }).then(res => {
				this.$util.funDownload(res.data, "GPS里程统计");
			});
		}
	}
};
</script>
<style lang="scss">
.el-table thead.is-group th.el-table__cell {
	background: #4077bc;
}
.el-table .el-table__cell {
	text-align: center;
}
</style>
